
import { defineComponent, ref, computed } from 'vue';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import Q from 'q';
import {
    ReportRow,
    FullReportRow,
    DateRange,
    TimelineReportRow,
    IndexProductStatistics,
    TimelineIndexStatistics,
    IndexUserChat,
    ItemPerProduct
} from '@/models/Interfaces';
import Calendar from 'primevue/calendar';
import PieBlock from './PieBlock.vue';
import TimelineBlock from './TimelineBlock.vue';
import moment from 'moment';
import useOrder from '@/modules/useOrder';
import Multiselect from '@vueform/multiselect';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    components: {
        PieBlock,
        Calendar,
        TimelineBlock,
        Multiselect
    },
    name: 'IndexStatistics',
    data() {
        return {};
    },
    async setup() {
        const { convertDate } = useOrder();
        const { t } = useI18n();
        const numberOfChats = ref(0);
        const numberOfQuotes = ref(0);
        const productsStatistics = ref<IndexProductStatistics[]>([]);
        const productsStatisticsTimelines = ref<TimelineIndexStatistics[]>([]);
        const chatsPerProducts = ref<ItemPerProduct[]>([]);
        const quotesPerProducts = ref<ItemPerProduct[]>([]);
        const indexUsersChats = ref<IndexUserChat[]>([]);
        const dateRange = ref<DateRange>(new DateRange());
        const productName = ref<string | null>(null);

        const colorScheme = [
            { colorStart: '#F17306', colorEnd: '#F68C1F' },
            { colorStart: '#7046BB', colorEnd: '#734BBD' },
            { colorStart: '#2C9CE5', colorEnd: '#1B78D3' },
            { colorStart: '#302CE5', colorEnd: '#18167B' },
            { colorStart: '#2CE58C', colorEnd: '#056839' },
            { colorStart: '#FE4A8B', colorEnd: '#6C1132' },
            { colorStart: '#BB6246', colorEnd: '#713421' },
            { colorStart: '#E1E52C', colorEnd: '#686A0E' },
            { colorStart: '#F05E78', colorEnd: '#61111F' },
            { colorStart: '#0BC7D9', colorEnd: '#26686F' }
        ];

        async function loadData() {
            swal.showLoading();
            const filter = {
                productId: null as string | null,
                fromDate: null as string | null,
                toDate: null as string | null
            };
            if (dateRange.value.fromDate) {
                filter.fromDate = convertDate(dateRange.value.fromDate);
            }
            if (dateRange.value.toDate) {
                filter.toDate = convertDate(dateRange.value.toDate);
            }
            productName.value = null;

            const apiResult = await api.GetIndexStatistics(filter);
            await Q.delay(400);
            if (apiResult.errorMessage || !apiResult.data) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            if (apiResult.data.productsStatistics[0]) productName.value = apiResult.data.productsStatistics[0].name;

            numberOfChats.value = apiResult.data.numberOfChats;
            numberOfQuotes.value = apiResult.data.numberOfQuotes;
            productsStatistics.value = apiResult.data.productsStatistics;
            productsStatisticsTimelines.value = apiResult.data.productsStatisticsTimelines;
            indexUsersChats.value = apiResult.data.indexUsersChats;
            chatsPerProducts.value = apiResult.data.chatsPerProducts;
            quotesPerProducts.value = apiResult.data.quotesPerProducts;
            swal.close();
        }

        const productPieData = computed(() => {
            return {
                productNumberOfQuotes: quotesPerProducts.value.map(x => ({
                    id: x.id,
                    title: x.totalCount + ' - ' + x.name,
                    value: x.totalCount
                })),
                productNumberOfChats: chatsPerProducts.value.map(x => ({
                    id: x.id,
                    title: x.totalCount + ' - ' + x.name,
                    value: x.totalCount
                }))
            };
        });

        const createBackgroundString = (i: number) => {
            return `linear-gradient(${90}deg, ${colorScheme[i].colorStart}, ${colorScheme[i].colorEnd})`;
        };

        function createSet(
            data: any[],
            labels: string[],
            label: string,
            fill: boolean,
            backgroundColor: string,
            pointRadius: number,
            borderWidth: number
        ) {
            return {
                datasets: [
                    {
                        backgroundColor,
                        data,
                        fill: fill,
                        label,
                        pointRadius,
                        borderWidth
                    }
                ],
                labels
            };
        }

        const timeline = computed(() => {
            const selectedProduct = productsStatisticsTimelines.value.find(
                x => x.id === productsStatistics.value.find(p => p.name === productName.value)?.id
            );
            const clicks = selectedProduct?.dates.map(function(item, index) {
                return { date: item, value: selectedProduct.showContactsClicksValues[index] };
            });
            const visits = selectedProduct?.dates.map(function(item, index) {
                return { date: item, value: selectedProduct.productPageVisitsValues[index] };
            });
            const displays = selectedProduct?.dates.map(function(item, index) {
                return { date: item, value: selectedProduct.productDisplayValues[index] };
            });

            const datasets = createSet(
                clicks?.map(x => ({ x: x.date, y: x.value })) ?? [],
                [],
                t('index-statistics.timeline-label.number-of-clicks'),
                false,
                '#1FB6FF',
                5,
                3
            );

            datasets.datasets.push({
                data: displays?.map(x => ({ x: x.date, y: x.value })) ?? [],
                fill: false,
                label: t('index-statistics.timeline-label.number-of-displays'),
                backgroundColor: '#F1354C',
                pointRadius: 5,
                borderWidth: 3
            });
            datasets.datasets.push({
                data: visits?.map(x => ({ x: x.date, y: x.value })) ?? [],
                fill: false,
                label: t('index-statistics.timeline-label.number-of-visits'),
                backgroundColor: '#FFBD43',
                pointRadius: 5,
                borderWidth: 3
            });

            return {
                data: datasets
            };
        });

        await loadData();

        function shortcut(type: string) {
            const now = moment();
            const week = moment().add('days', 1 - now.weekday());
            const month = moment().add('days', 1 - now.dates());
            switch (type) {
                case 'today':
                    dateRange.value.fromDate = now.toDate();
                    dateRange.value.toDate = now.toDate();
                    break;
                case 'yesterday':
                    dateRange.value.fromDate = now
                        .clone()
                        .add('day', -1)
                        .toDate();
                    dateRange.value.toDate = now
                        .clone()
                        .add('day', -1)
                        .toDate();
                    break;
                case 'week':
                    dateRange.value.fromDate = week.toDate();
                    dateRange.value.toDate = week
                        .clone()
                        .add('week', 1)
                        .add('day', -1)
                        .toDate();
                    break;
                case 'month':
                    dateRange.value.fromDate = month.toDate();
                    dateRange.value.toDate = month
                        .clone()
                        .add('month', 1)
                        .add('day', -1)
                        .toDate();
                    break;
                default:
                    return;
            }
            loadData();
        }

        function showAll() {
            dateRange.value = new DateRange();
            loadData();
        }

        return {
            numberOfChats,
            numberOfQuotes,
            productsStatistics,
            indexUsersChats,
            productPieData,
            dateRange,
            loadData,
            showAll,
            timeline,
            shortcut,
            colorScheme,
            productName,
            createBackgroundString
        };
    }
});
